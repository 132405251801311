import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import favicon from 'assets/images/favicon.ico';

const SEO = ({ pageTitle, pageDescription }) => (
	<StaticQuery
		query={graphql`
			{
				site {
					siteMetadata {
						title
						description
						canonicalUrl
						image
						image_twitter
						image_og
						keywords
						organization {
							name
							url
							logo
						}
						social {
							twitter
							fbAppID
						}
					}
				}
			}
		`}
		render={({ site: { siteMetadata: seo } }) => {
			const { title, description, image, url, image_twitter, image_og, keywords } = seo;
			const baseSchema = [
				{
					'@context': 'http://schema.org',
					'@type': 'WebSite',
					url,
					name: title,
					alternateName: title
				}
			];

			return (
				<React.Fragment>
					<Helmet>
						{/* General tags */}
						<title>{pageTitle || title}</title>
						<meta name="description" content={pageDescription || description} />
						<meta name="image" content={image} />
						<meta name="keywords" content={keywords} />
						<meta name="author" content="Srikanth Bandaru" />

						<link rel="shortcut icon" href={favicon} />
						<link rel="apple-touch-icon-precomposed" href={favicon} />
						<link rel="canonical" href={url} />

						{/* OpenGraph tags */}
						<meta property="og:url" content={url} />
						<meta property="og:title" content={title} />
						<meta property="og:description" content={description} />
						<meta property="og:image" content={image_og} />
						<meta property="fb:app_id" content={seo.social.fbAppID} />

						{/* Twitter Card tags */}
						<meta name="twitter:card" content="summary_large_image" />
						<meta name="twitter:creator" content={seo.social.twitter} />
						<meta name="twitter:title" content={title} />
						<meta name="twitter:description" content={description} />
						<meta name="twitter:image" content={image_twitter} />
					</Helmet>

					{/* Schema.org tags */}
					<script type="application/ld+json">{JSON.stringify(baseSchema)}</script>
				</React.Fragment>
			);
		}}
	/>
);

SEO.propTypes = {
	postImage: PropTypes.string
};

SEO.defaultProps = {
	postImage: null
};

export default SEO;
