const jsonResumeSchema = {
	basics: {
		name: 'Srikanth Bandaru',
		label: 'Senior Software Engineer',
		picture: '',
		email: 'hello@bsrikanth.com',
		phone: '+1 (470) 848-3001',
		website: 'https://bsrikanth.com',
		summary:
			'I care deeply about the people I work with. I bring empathy and strong communication to teams, and strive to learn and teach as much as I can.',
		location: {
			address: 'Saint Louis, MO',
			postalCode: '63043',
			city: 'Saint Louis',
			countryCode: 'US',
			region: 'Missouri'
		},
		profiles: [
			{
				network: 'Twitter',
				username: 'srikanthbandaru',
				url: 'https://twitter.com/srikanthbandaru'
			},
			{
				network: 'GitHub',
				username: 'SrikanthBandaru',
				url: 'https://github.com/srikanthbandaru/'
			},
			{
				network: 'LinkedIn',
				username: 'SrikanthBandaru',
				url: 'https://linkedin.com/in/srikanthbandaru/'
			}
		]
	},
	work: [
		{
			company: 'Bayer U.S. LLC',
			shortName: 'bayer',
			position: 'Software Developer',
			website: 'https://www.bayer.com/en/',
			logoURL: 'https://www.bayer.com/themes/custom/bayer_cpa/logo.svg',
			startDate: '12/16/2019',
			endDate: 'Present',
			location: 'St Louis, MO',
			summary:
				'Developing large-scale enterprise web applications using functional & point-free style JavaScript, React, Node, and PostgreSQL. Built a proof of concept and am currently leading the development efforts of an in-house, scalable and collaborative platform for reusable components.',
			responsibilities: [
				'Developing large-scale enterprise web applications using functional & point-free style JavaScript, React, Node, and PostgreSQL.',
				'Supporting application development, including research, design, architecture, programming, and testing. Wrote complex stored procedures to extract enterprise data.',
				'Built a proof of concept and am currently leading the development efforts of an in-house, scalable, and collaborative platform for reusable components.',
				'Interviewed candidates to help bring great talent to Bayer.'
			],
			highlights: ['Started the company'],
			technologies: [
				'JavaScript',
				'Functional Programming',
				'React',
				'Redux',
				'Node.js',
				'PostgreSQL & pgAdmin',
				'Flyway',
				'AWS',
				'Jest',
				'Puppeteer',
				'Jenkins'
			]
		},
		{
			company: 'Cognizant Technology Solutions',
			shortName: 'cognizant',
			position: 'Technical Lead',
			website: 'https://www.cognizant.com/',
			logoURL: 'https://www.cognizant.com/content/dam/cognizant_foundation/Dotcomimage/COG-Logo-White.svg',
			startDate: '04/10/2017',
			endDate: '12/13/2019',
			location: 'St Louis, MO',
			summary:
				'Led rapid development of an internal application for our clients from concept to working software and customer trails. Oversaw organizational change of our front-end stack (to React.js + Redux). This required understanding and managing the needs of the company, developers and clients, and planning, communicating and architecting the change.',
			responsibilities: [
				'Led rapid development of an internal application for our clients from concept to working software and customer trails.',
				'Responsible for all aspects of front-end architecture and development, including business focused trade-offs around technology, features, quality and test strategy. ',
				'Oversaw organizational change of our front-end stack (to React.js + Redux). This required understanding and managing the needs of the company, developers and clients, and planning, communicating and architecting the change.',
				'Designed and built <a href="https://github.com/srikanthbandaru/reactjs-input-validator" target="_blank">Reactjs Input Validator</a> (an input field validation library) to help developers write clean code for better field validations in our client applications.',
				'Hired and mentored interns and junior developers.'
			],
			highlights: ['Started the company'],
			technologies: [
				'JavaScript',
				'React',
				'Redux',
				'Node.js',
				'Functional Programming',
				'PostgreSQL & pgAdmin',
				'Jest',
				'Puppeteer',
				'Docker',
				'PCF',
			]
		},
		{
			company: 'InfoDrive Systems LLC',
			shortName: 'infoDrive',
			position: 'Programmer Analyst',
			website: 'http://company.com',
			logoURL: 'https://i.imgur.com/LXOv7ar.png',
			startDate: '03/24/2016',
			endDate: '04/07/2017',
			location: 'St Louis, MO',
			summary:
				'Led a team of 11 in architecture, development, test strategy and maintenance of a public facing, mobile first, cross-device compatible web application built for our corporate clients. Delighted clients on a range of real-time web application projects with the rapid development of new products, adding real-time features to existing products, code and architecture review of existing codebases.',
			highlights: ['Started the company'],
			responsibilities: [
				'Led a team of 11 in architecture, development, test strategy and maintenance of a public facing, mobile first, cross-device compatible web application built for our corporate clients.',
				'Delighted clients on a range of real-time web application projects. Rapid development of new products, adding real-time features to existing products, code and architecture review of existing codebases.',
				'Owned technical relationship with clients to understand their needs and prioritized work, and provided well documented clean code to their internal teams.',
				'Wrote batch scripts to automate tasks that saved more than 2000 working hours for our team.',
				'Gave ReactJS training to other dev teams in our organization.'
			],
			technologies: ['JavaScript', 'React', 'Redux', 'Node.js', 'Oracle', 'PostgreSQL', 'Jest', 'CSS modules', 'Docker', 'PCF']
		},
		{
			company: 'Open Future Institute',
			shortName: 'ofi',
			position: 'Technology Intern',
			website: 'http://company.com',
			logoURL: 'https://openfutureinstitute.org/wp-content/uploads/2015/08/OFI-LOGO.png',
			startDate: '06/17/2015',
			endDate: '08/31/2015',
			location: 'New York, NY',
			summary:
				'Designed and developed prototypes into fully functional websites from scratch and worked with managers in agile environment & trained employees on how to use WordPress. Translated specifications and requirements into the code to complete more complex program changes.',
			highlights: ['Started the company'],
			responsibilities: [
				'Designed and developed prototypes into fully functional websites from scratch.',
				'Worked with managers in agile environment & trained employees on how to use WordPress',
				'Translated specifications and requirements into the code to complete more complex program changes.',
				'Recognized code, process, and standard inefficiencies and made suggestions for improvement.'
			],
			technologies: ['JavaScript', 'PHP', 'Postgres', 'WordPress', 'SEO', 'HTML', 'Bootstrap']
		},
		{
			company: 'Pace University, eLab',
			shortName: 'paceUniversity',
			position: 'Student Assistant',
			website: 'http://company.com',
			logoURL: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Pace_University_logo.png',
			startDate: '09/11/2014',
			endDate: '12/18/2016',
			location: 'New York, NY',
			summary:
				"I have created and administered Entrepreneurship Lab’s Moodle-based Competition Management System. Worked on Drupal nodes and blocks and developed the web pages using HTML, CSS3, JavaScript and Bootstrap. I have also provided graphical support for Entrepreneurship Lab's (eLab) websites and social media pages by designing digital graphics.",
			highlights: ['Started the company'],
			responsibilities: [
				'Created and administered Entrepreneurship Lab’s Moodle-based Competition Management System',
				'Worked on Drupal nodes and blocks and developed the web pages using HTML, CSS3, JavaScript and Bootstrap.',
				"Provided graphical support for Entrepreneurship Lab's (eLab) websites and social media pages by designing digital graphics.",
				'Created a database schema for an extensive data set for an internal web project.'
			],
			technologies: ['JavaScript', 'PHP', 'jQuery', 'Heroku', 'Oracle', 'SEO', 'HTML', 'CSS3', 'Bootstrap']
		},
		{
			company: 'Color Layers',
			shortName: 'colorLayers',
			position: 'Freelance Web Developer',
			website: 'http://company.com',
			logoURL: 'http://colorlayers.com/wp-content/uploads/2015/03/finaleeeeeeeeeeeeeeeeeeeeee.png',
			startDate: '08/11/2011',
			endDate: '07/18/2014',
			location: 'Hyderabad, India',
			summary:
				'I have helped clients in bringing their ideas to life through design-oriented solutions. Worked as a web strategy consultant specialized in front-end development. Redesigned client’s websites using best SEO practices to optimize them for search engine rankings. Also involved in pitching, customer development, marketing, sales, and support.',
			highlights: ['Started the company'],
			responsibilities: [
				'Helped clients in bringing their ideas to life through design-oriented solutions.',
				'Worked as a web strategy consultant specialized in front-end development.',
				'Redesigned client’s websites using best SEO practices to optimize them for search engine rankings.',
				'Created complex search pages interacting with SQL Server and integrated maps and videos in forms and panels in Joomla layouts.',
				'I am also involved in pitching, customer development, marketing, sales, and support.'
			],
			technologies: ['PHP', 'JavaScript', 'jQuery', 'Wordpress', 'Heroku', 'MySQL']
		}
	],
	portfolio: [
		{
			projectName: '',
			category: '',
			technologies: '',
			images: ['image1.jpg', 'image2.jpg'],
			description: '',
			link: ''
		}
	],
	volunteer: [
		{
			organization: 'Organization',
			position: 'Volunteer',
			website: 'http://organization.com/',
			startDate: '2012-01-01',
			endDate: '2013-01-01',
			summary: 'Description...',
			highlights: ["Awarded 'Volunteer of the Month'"]
		}
	],
	education: [
		{
			institution: 'Pace University',
			area: 'Computer Science',
			studyType: 'Master of Science',
			startDate: '2011-01-01',
			endDate: '12/18/2015',
			gpa: '4.0',
			courses: ['DB1101 - Basic SQL']
		}
	],
	awards: [
		{
			title: 'Award',
			date: '2014-11-01',
			awarder: 'Company',
			summary: 'There is no spoon.'
		}
	],
	publications: [
		{
			name: 'Publication',
			publisher: 'Company',
			releaseDate: '2014-10-01',
			website: 'http://publication.com',
			summary: 'Description...'
		}
	],
	skills: [
		{
			name: 'Web Development',
			level: 'Master',
			keywords: ['HTML', 'CSS', 'Javascript']
		}
	],
	languages: [
		{
			name: 'English',
			level: 'Native speaker'
		}
	],
	interests: [
		{
			name: 'Wildlife',
			keywords: ['Ferrets', 'Unicorns']
		}
	],
	references: [
		{
			name: 'Srikanth Bandaru',
			reference: 'Reference...'
		}
	]
};

module.exports = jsonResumeSchema;
